import { CSSProperties } from "react";


export const styles: Record<string, CSSProperties> = {
  root: {
    outline: "none",
    border: "1px solid rgba(0, 0, 0, 0.2)",
    fontSize: 15,
    padding: "5px 15px",
    height: "30px",
    borderRadius: "15px",
    backgroundColor: "#1b2036",
    color: "white",
    fontWeight: 400,
  },
};
