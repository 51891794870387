import React, { FC } from "react";
import { styles } from "./styles";


export enum ViewModes {
  CARDS = "CARDS",
  LIST = "LIST",
}

interface IToggleViewButtonProps {
  viewMode: ViewModes;
  setViewMode: (mode: ViewModes) => void;
}

export const ToggleViewButton: FC<IToggleViewButtonProps> = props => {
  const { viewMode, setViewMode } = props;

  return viewMode === ViewModes.CARDS ? (
    <div
      onClick={(): void => {
        setViewMode(ViewModes.LIST);
      }}
      style={{
        width: "20px",
      }}
    >
      <div
        style={{
          height: "2px",
          backgroundColor: "white",
          marginBottom: "4px",
        }}
      />
      <div style={styles.listCell} />
      <div style={{ height: "2px", backgroundColor: "white" }} />
    </div>
  ) : (
    <div
      style={{ width: "20px" }}
      onClick={(): void => {
        setViewMode(ViewModes.CARDS);
      }}
    >
      <div style={{ display: "flex" }}>
        <div style={styles.cardCell} />
        <div style={styles.cardCell} />
      </div>
      <div style={{ display: "flex" }}>
        <div style={styles.cardCell} />
        <div style={styles.cardCell} />
      </div>
    </div>
  );
};
