import { CSSProperties } from "react";


export const styles: Record<string, CSSProperties> = {
  root: {
    height: "335px",
    width: "200px",
    padding: "0",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  center: {
    padding: "10px",
    display: "flex",
    alignItems: "center",
  },
  indicator: {
    marginRight: "10px",
  },
  titleContainer: {
    backgroundSize: "cover",
    flex: 1,
  },
  title: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    padding: "5px",
    textAlign: "center",
    color: "white",
  },
};
