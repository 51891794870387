import React, { memo } from "react";
import { navigate } from "gatsby";
import { StreamCard } from "./StreamCard";
import { StreamRow } from "./StreanRow";
import { ViewModes } from "../ToggleViewButton";
import { IStream } from "../../../types/stream";


interface IStreamListProps {
  viewMode: ViewModes;
  streams: Array<IStream>;
}

export const StreamList = memo<IStreamListProps>(props => {
  const { viewMode, streams } = props;

  if (!streams.length) {
    return <h2>Ingen streams fundet</h2>;
  }

  if (viewMode === ViewModes.CARDS) {
    return (
      <div
        key="content-card"
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          flex: 1,
        }}
      >
        {streams.map((stream: IStream) => (
          <StreamCard
            key={stream.id}
            stream={stream}
            style={{ margin: "20px" }}
            onClick={(): void => {
              navigate(`/stream#${stream.id}`);
            }}
          />
        ))}
      </div>
    );
  } else {
    return (
      <div
        key="content-list"
        style={{
          maxWidth: "700px",
          margin: "0 auto",
        }}
      >
        {streams.map((stream: IStream) => (
          <StreamRow key={stream.id} stream={stream} />
        ))}
      </div>
    );
  }
});
