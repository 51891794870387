import React, { ChangeEvent, FC, ReactElement } from "react";


export enum SorterDirections {
  asc = "asc",
  desc = "desc",
}

export enum SorterFields {
  updated = "updated",
  name = "name",
}

export interface ISorter {
  field: SorterFields;
  direction: SorterDirections;
}

interface IFiltersProps {
  sorter: ISorter;
  setSorter: (sorter: ISorter) => void;
}

export const Sorter: FC<IFiltersProps> = props => {
  const { sorter, setSorter } = props;

  const handleChangeField = (e: ChangeEvent<HTMLSelectElement>): void => {
    setSorter({
      ...sorter,
      field: e.target.value as SorterFields,
    });
  };
  const handleChangeOption = (e: ChangeEvent<HTMLSelectElement>): void => {
    setSorter({
      ...sorter,
      direction: e.target.value as SorterDirections,
    });
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <select onChange={handleChangeField}>
        {Object.values(SorterFields).map(
          (field): ReactElement => (
            <option key={field} value={field} selected={field === sorter.field}>
              {field}
            </option>
          ),
        )}
      </select>
      <select onChange={handleChangeOption}>
        {Object.values(SorterDirections).map(
          (direction): ReactElement => (
            <option key={direction} value={direction} selected={direction === sorter.direction}>
              {direction}
            </option>
          ),
        )}
      </select>
    </div>
  );
};
