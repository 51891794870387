import React, { ChangeEvent, FC, useEffect, useState, Fragment } from "react";
import { navigate } from "gatsby";

import { MenuBarPortal } from "./MenuBarPortal";
import { LoadingIndicator } from "../LoadingIndicator";
import { SearchBar } from "./SearchBar";
import { query } from "../../api";
import { isAuthenticated } from "../../auth";
import { StreamList } from "./StreamList";
import { ToggleViewButton, ViewModes } from "./ToggleViewButton";
import { IStream } from "../../types/stream";
import { Filters, IFilters } from "./Filters";
import { Sorter, SorterDirections, SorterFields, ISorter } from "./Sorters";


const sortingFn = {
  [SorterFields.updated]: {
    [SorterDirections.asc]: (a: IStream, b: IStream): number => {
      return new Date(parseInt(b.updatedAt)).getTime() - new Date(parseInt(a.updatedAt)).getTime();
    },
    [SorterDirections.desc]: (a: IStream, b: IStream): number => {
      return new Date(parseInt(a.updatedAt)).getTime() - new Date(parseInt(b.updatedAt)).getTime();
    },
  },
  [SorterFields.name]: {
    [SorterDirections.asc]: (a: IStream, b: IStream): number => {
      return a.name.localeCompare(b.name);
    },
    [SorterDirections.desc]: (a: IStream, b: IStream): number => {
      return b.name.localeCompare(a.name);
    },
  },
};

export const Home: FC = () => {
  const [filters, setFilters] = useState({
    showCinemas: true,
    showUserStreams: true,
    showOnline: true,
    showDisconnected: true,
    showOffline: true,
    filter20Hours: false,
    filter24Hours: false,
  });
  const [sorter, setSorter] = useState<ISorter>({
    field: SorterFields.updated,
    direction: SorterDirections.desc,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [viewMode, setViewMode] = useState(ViewModes.LIST);
  const [streams, setStreams] = useState<Array<IStream>>([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/login");
    }
    setIsLoading(true);
    query(`
      {
        streams {
          id
          name
          online
          connected
          updatedAt
          owner {
            id
          }
          subtitles {
            language
          }
          info {
            title
            cover {
              uri
            }
            backdrop {
              uri
            }
          }

        }
      }
    `)
      .then(({ streams }: { streams: Array<IStream> }) => {
        setStreams(streams);
        setIsLoading(false);
      })
      .catch(() => {
        setStreams([]);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  const filteredStreams = streams
    .filter((stream: IStream) => {
      return (filters.showCinemas && stream.owner === null) || (filters.showUserStreams && stream.owner !== null);
    })
    .filter((stream: IStream) => {
      return (
        (filters.showOffline && !stream.online)
        || (filters.showDisconnected && !stream.connected && stream.online)
        || (filters.showOnline && stream.connected && stream.online)
      );
    })
    .filter((stream: IStream) => {
      if (!filters.filter20Hours && !filters.filter24Hours) {
        return true;
      }

      const oneHour = 60 * 60 * 1000;
      const lastUpdated = new Date().getTime() - new Date(parseInt(stream.updatedAt)).getTime();

      return (
        (filters.filter20Hours && lastUpdated > 20 * oneHour && lastUpdated < 24 * oneHour)
        || (filters.filter24Hours && lastUpdated > 24 * oneHour)
      );
    })
    .filter((stream: IStream) => {
      return (
        stream.name.toLowerCase().includes(searchQuery.toLowerCase())
        || stream.info.title.toLowerCase().includes(searchQuery.toLowerCase())
        || (stream.online
          ? "online".startsWith(searchQuery.toLowerCase())
          : "offline".startsWith(searchQuery.toLowerCase()))
      );
    });

  const sortedStreams = filteredStreams.sort(sortingFn[sorter.field][sorter.direction]);

  const updateFilters = (newFilters: Partial<IFilters>): void => {
    setFilters({
      ...filters,
      ...newFilters,
    });
  };

  return (
    <Fragment>
      <StreamList streams={sortedStreams} viewMode={viewMode} />
      <MenuBarPortal key="menubar">
        <div style={{ marginRight: "10px" }}>
          <Filters filters={filters} setFilters={updateFilters} />
          <Sorter sorter={sorter} setSorter={setSorter} />
        </div>
        <SearchBar
          placeholder={"Søg streams"}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => {
            setSearchQuery(e.target.value);
          }}
          value={searchQuery}
        />
        <div
          style={{
            marginLeft: "10px",
          }}
        >
          <ToggleViewButton
            viewMode={viewMode}
            setViewMode={(mode: ViewModes): void => {
              setViewMode(mode);
            }}
          />
        </div>
      </MenuBarPortal>
    </Fragment>
  );
};
