import { CSSProperties } from "react";


export const styles: Record<string, CSSProperties> = {
  cardCell: {
    width: "8px",
    height: "8px",
    border: "1px solid white",
    borderRadius: "1px",
    margin: "1px",
  },
  listCell: {
    height: "2px",
    backgroundColor: "white",
    marginBottom: "4px",
  },
};
