import React, { CSSProperties, FC } from "react";


interface ICircleProps {
  diameter: number;
  style: CSSProperties;
}

export const Circle: FC<ICircleProps> = props => {
  const { diameter, style, ...rest } = props;
  return (
    <div
      {...rest}
      style={{
        width: `${diameter}px`,
        height: `${diameter}px`,
        borderRadius: `${diameter / 2}px`,
        ...style,
      }}
    />
  );
};
