import React, { ChangeEvent, FC } from "react";
import { Circle } from "../../Circle";


export interface IFilters {
  showCinemas: boolean;
  showUserStreams: boolean;
  showDisconnected: boolean;
  showOnline: boolean;
  showOffline: boolean;
  filter20Hours: boolean;
  filter24Hours: boolean;
}

interface IFiltersProps {
  filters: IFilters;
  setFilters: (filters: Partial<IFilters>) => void;
}

export const Filters: FC<IFiltersProps> = props => {
  const { filters, setFilters } = props;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <label>
        <input
          type="checkbox"
          checked={filters.showCinemas}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => {
            setFilters({ showCinemas: e.target.checked });
          }}
        />
        <span>Cinemas</span>
      </label>
      <label>
        <input
          type="checkbox"
          checked={filters.showUserStreams}
          onChange={(e: ChangeEvent<HTMLInputElement>): void =>
            setFilters({
              showUserStreams: e.target.checked,
            })
          }
        />
        <span>Userstreams</span>
      </label>
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          type="checkbox"
          checked={filters.showOnline}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => {
            setFilters({ showOnline: e.target.checked });
          }}
        />
        <Circle
          diameter={10}
          style={{
            backgroundColor: "#00fa64",
          }}
        />
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          type="checkbox"
          checked={filters.showDisconnected}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => {
            setFilters({ showDisconnected: e.target.checked });
          }}
        />
        <Circle
          diameter={10}
          style={{
            backgroundColor: "#ffaa00",
          }}
        />
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          type="checkbox"
          checked={filters.showOffline}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => {
            setFilters({ showOffline: e.target.checked });
          }}
        />
        <Circle
          diameter={10}
          style={{
            backgroundColor: "#f9005b",
          }}
        />
      </div>
      <input
        type="checkbox"
        checked={filters.filter20Hours}
        onChange={(e: ChangeEvent<HTMLInputElement>): void => {
          setFilters({ filter20Hours: e.target.checked });
        }}
      />
      <a
        style={{
          fontSize: "15px",
          fontWeight: 900,
          margin: "3px",
        }}
      >
        !
      </a>
      <input
        type="checkbox"
        checked={filters.filter24Hours}
        onChange={(e: ChangeEvent<HTMLInputElement>): void => {
          setFilters({ filter24Hours: e.target.checked });
        }}
      />
      <a
        style={{
          fontSize: "15px",
          fontWeight: 900,
          margin: "3px",
        }}
      >
        !!
      </a>
    </div>
  );
};
