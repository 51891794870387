import React, { memo } from "react";
import { navigate } from "gatsby";
import { StatusIndicator } from "../../../StatusIndicator";
import { IStream } from "../../../../types/stream";
import { ISubtitle } from "../../../../types/subtitle";


interface IStreamRowProps {
  stream: IStream;
}

export const StreamRow = memo<IStreamRowProps>(props => {
  const { stream } = props;
  return (
    <div
      key={stream.id}
      style={{
        marginBottom: "10px",
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        padding: "15px",
        display: "flex",
        alignItems: "center",
        borderRadius: "5px",
        cursor: "pointer",
        justifyContent: "space-between",
      }}
      onClick={(): void => {
        navigate(`/stream#${stream.id}`);
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <StatusIndicator
          diameter={8}
          stream={stream}
          style={{
            marginRight: "10px",
          }}
        />
        {stream.name}
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        {stream.subtitles
          .map((s: ISubtitle) => s.language)
          .map((lang: string) => {
            return (
              <div
                key={lang}
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.2)",
                  padding: "4px 8px",
                  borderRadius: "4px",
                  marginLeft: "5px",
                }}
              >
                {lang}
              </div>
            );
          })}
      </div>
    </div>
  );
});
