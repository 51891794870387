import React, { CSSProperties, FC } from "react";

import { Circle } from "../Circle";
import { IStream } from "../../types/stream";


interface IStatusIndicatorProps {
  diameter: number;
  style: CSSProperties;
  stream: IStream;
}

export const StatusIndicator: FC<IStatusIndicatorProps> = props => {
  const { diameter, stream, style, ...rest } = props;

  // Date is formatted differently based on whether we get it
  // from the graphql api of the REST API, so we try to parse
  // the date and if it doesn't work we parse stream.lastUpdated
  // as an int and use that.
  let date = new Date(stream.updatedAt);

  if (isNaN(date.getTime())) {
    date = new Date(parseInt(stream.updatedAt));
  }

  const lastUpdated = Date.now() - date.getTime();
  const oneHour = 60 * 60 * 1000;
  const oneDay = 24 * oneHour;

  if (lastUpdated < 20 * oneHour) {
    return (
      <Circle
        {...rest}
        diameter={diameter}
        style={{
          backgroundColor: stream.online ? (stream.connected ? "#00fa64" : "#ffaa00") : "#f9005b",
          ...style,
        }}
      />
    );
  } else {
    return (
      <a
        style={{
          fontSize: `${2 * diameter}px`,
          fontWeight: 900,
          color: stream.online ? (stream.connected ? "#00fa64" : "#ffaa00") : "#f9005b",
          ...style,
        }}
      >
        {lastUpdated > oneDay ? "!!" : "!"}
      </a>
    );
  }
};
