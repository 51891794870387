import React, { CSSProperties, memo } from "react";

import { StatusIndicator } from "../../../StatusIndicator";
import { Card } from "../../../Card";
import { styles } from "./styles";
import { IStream } from "../../../../types/stream";


interface IStreamCardProps {
  stream: IStream;
  style: CSSProperties;
  onClick: () => void;
}

export const StreamCard = memo<IStreamCardProps>(props => {
  const { stream, style, ...rest } = props;
  return (
    <Card
      {...rest}
      style={{
        ...styles.root,
        ...style,
      }}
      className="stream-card"
    >
      <div style={styles.center}>
        <StatusIndicator diameter={10} stream={stream} style={styles.indicator} />
        <span style={{ color: "white" }}>{stream.name}</span>
      </div>
      <div
        style={{
          ...styles.titleContainer,
          backgroundImage: `url(${stream.info.cover.uri})`,
        }}
      >
        <div style={styles.title}>{stream.info.title}</div>
      </div>
    </Card>
  );
});
