import React, { FC, DetailedHTMLProps, InputHTMLAttributes } from "react";
import { styles } from "./styles";


export const SearchBar: FC<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>> = props => {
  const { style, ...rest } = props;
  return (
    <input
      autoFocus
      type="text"
      {...rest}
      style={{
        ...styles.root,
        ...style,
      }}
    />
  );
};
